import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/UI/Layout"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Typography from "../components/Utils/Typography"
import Seo from "../components/Seo"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import Button from "../components/Utils/Button"
import SmsIcon from '@material-ui/icons/Sms';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';

import ContactForm from "../components/ContactForm"

import MyMap from "../components/Utils/MyMap"



const styles = theme => ({
  h1: {
    fontSize: 60,   
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,      
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,      
    },    
  },
  h5: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,      
    },   
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,      
    },      
  },
  more: {
    marginTop: theme.spacing(2),
  },
})


function Kontakt(props) {
  const { classes } = props
  /* const theme = useTheme() */
  return (
    <Layout>
      <Seo  title="Kontakt" description="Kontaktseite der Privatpraxis ESTETIESE Dr. med. Anna Maria Müller für die Website emsculpt-point.de und assozierte Social Media Seiten."/>
      <Container>
        <Box mt={7} mb={12}>
          <Typography variant="h2" gutterBottom marked="center" align="center">
            Kontakt
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            align="center"
            style={{ height: "5vh" }}          >
          </Typography>
        </Box>

      <Typography>

      <h3><span style={{color: '#CDCDCD'}}>
            Adresse:              
              </span></h3>
            <p>                   
              <emsp /> Privatpraxis ESTETIESE<br /> <br />
              <emsp /> Dr. med. Anna Maria Müller<br />
              <emsp />Norderoog 1<br />
              <emsp />28259 Bremen
            </p>
        <aside>
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<EmailIcon/>}
            // component={Link}
            href={"mailto:info@extetiese.de?subject=Kontaktanfrage für EMSulpt NEO&body=Datenschutz: Ihre Daten werden nur für die Kontaktaufnahme verwendet und nicht gespichert. Bitte nehmen Sie mit mir Kontakt auf. Mein Anliegen ..."}
             >
            E-Mail: info@estetiese.de
            </Button>
            <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<CallIcon/>}
            // component={Link}
            href={"tel:016090623273"}
            >
            Tel.: +49 (0) 160 / 90623273
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<SmsIcon/>}
              // component={Link}
              href={"sms:016090623273"}    
              >
              SMS: +49 (0) 160 / 90623273
              </Button>
              

        </aside>

        <ContactForm></ContactForm>
        <aside>
          <h3><span style={{color: '#CDCDCD'}}>
            Anreise:              
          </span></h3>
          <Typography             
            variant="body1"
            gutterBottom
            align="justify"  >
          Ab Hauptbahnhof fahren Sie mit der Linie 1 oder 8 von Domsheide in Richtung Huchting (Roland-Center). 
          Dort steigen Sie um und nehmen den Bus 58 in Richtung Mittelshuchting bis zur Haltestelle Carl-Hurzig-Straße. 
          Gehen Sie ca. 150 Meter zurück. Die Strasse Norderoog 1 befindet sich gegenüber der Rückseite der Aral-Tankstelle.
          Unsere Praxis finden Sie im 1. Stock, rechter Hand."
          </Typography>
        </aside>
        <MyMap />
       
      </Typography>
      </Container>
    </Layout>
  )
}

Kontakt.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Kontakt)
